import React, { useState } from "react";
import styled from "styled-components";
import HeadingBarPopup from "../Content/HeadingBarPopup";
import Draggable from "react-draggable";
import theme from "../../styles/theme";


const Container = styled.div`
    position: absolute;
	border-radius: 0.6rem 0.6rem 0.3rem 0.3rem;
	box-shadow: ${theme.windowShadow} 0px 1px 4px;
	resize: ${props => (props.resizable ? `both` : `none`)};
	overflow: hidden;
	width: 30%;
	height: 50%;
	max-height: 90%;
	max-width: 80%;
	backdrop-filter: blur(1rem);
	background: ${theme.bodyBgWithOpacity};
	${props => props.height && `height: ${props.height}`}
`;

const Popup = props => {
	let resizable = false;
	if (props.resizable === undefined) {
		resizable = true;
	}
	const BOUND = 512;


    const [isVisible, setIsVisible] = useState(true);
    const handleClose = () => {
        props.updateKey(props.componentKey);
        setIsVisible(false);
    };
  
	return (
		<>
            {isVisible && (<Draggable
                bounds={{
                    top: -128,
                    left: -BOUND,
                    right: BOUND,
                    bottom: BOUND,
                }}
                handle=".heading-bar"
            >
                <Container
                    height={props.height}
                    resizable={resizable}
                    onContextMenu={e => {
                        !props.contextMenu && e.preventDefault();
                    }}
                >
                    <HeadingBarPopup
                        altClassName="heading-bar"
                        heading={props.heading}
                        onClose={handleClose}
                    />
                    {props.children}

                </Container>
            </Draggable>
            )}
			
		</>
	);
};

export default Popup;
