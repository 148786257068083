import React from 'react';
import './App.css';
import Routing from './Routing/Routing.jsx'
import { ThemeProvider } from "styled-components";
import colors from "./styles/colors";
import DataProvider from "./contexts/Data/DataProvider";


export default function App() {
  return (
    <ThemeProvider theme={colors}>
			<DataProvider>
				<Routing />
			</DataProvider>
		</ThemeProvider>
  );
}



