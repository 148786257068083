import React from "react";
import "../../styles/dock.scss";
import FinderIcon from "../../static/finder.png";
import CodeIcon from "../../static/code.png";
import GithubIcon from "../../static/github.png";
import FolderIcon from "../../static/folder.png";
import LinkedInIcon from "../../static/LinkedInIcon.png";
import TextEditIcon from "../../static/textedit.png";
import ResumeIcon from "../../static/preview.png";


import { Link } from "react-router-dom";


const DockContent = () => {

	const handleLink = (event) => {
		if (event.currentTarget.pathname === window.location.pathname) {
			window.location.reload(true);
		}
	};
	return (
		<div className="main-contain">
			<div className="container">
				<div className="dock">
					<span></span>
					<div className="dock-nav">
						<ul>
							<Link to="/" onClick={handleLink}>
								<li
									data-title="Home"
									className="full-width-icon"
								>
									<img
										src={FinderIcon}
										className="img-fluid"
										alt="mac"
									/>
								</li>
							</Link>
							<Link to="/vscode" onClick={handleLink}>
								<li data-title="VS Code">
									<img
										src={CodeIcon}
										className="img-fluid"
										alt="mac"
									/>
								</li>
							</Link>
							<a href="https://github.com/AlexanderL3000" rel="noreferrer" target="_blank" >
								<li data-title="Github">
									<img
										src={GithubIcon}
										className="img-fluid"
										alt="mac"
									/>
								</li>
							</a>
							<a href="https://www.linkedin.com/in/alexandertqli" rel="noreferrer" target="_blank">
								<li data-title="LinkedIn">
									<img
										src={LinkedInIcon}
										className="img-fluid"
										alt="mac"
									/>
								</li>
							</a>
							<div className="separator" />
							<Link to="/projects" onClick={handleLink}>
								<li data-title="Projects">
									<img
										src={FolderIcon}
										className="img-fluid"
										alt="mac"
									/>
								</li>
							</Link>
							<Link to="/resume" onClick={handleLink}>
								<li data-title="Resume">
									<img
										src={ResumeIcon}
										className="img-fluid"
										alt="mac"
									/>
								</li>
							</Link>
							<div className="separator" />

							<Link to="/contact" onClick={handleLink}>
								<li
									data-title="Contact"
								>
									<img
										src={TextEditIcon}
										className="img-fluid"
										alt="mac"
									/>
								</li>
							</Link>

						</ul>
					</div>
				</div>
			</div>
		</div>
	);
};

export default DockContent;