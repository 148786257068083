import React, { Suspense, lazy } from "react";
import Layout from "./Layout";
import Loader from "../Loader";
import Default from "./Default";
const ContactContent = lazy(() => import("../Content/ContactContent"));

const Contact = () => {
	return (
		<Layout programName="Text Edit">
			<Default height="60%" heading="Contact Page" resizable={false}>
				<Suspense fallback={<Loader />}>
						<ContactContent />
				</Suspense>
			</Default>
		</Layout>
	);
};

export default Contact;
