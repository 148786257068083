import React, { Suspense, lazy } from "react";
import Layout from "./Layout";
import Loader from "../Loader";
import Default from "./Default";

const TerminalContent = lazy(() =>
	import("../Content/TerminalContent")
);

const Window = () => {
	return (
		<Layout programName="Terminal">
			<Default contextMenu={true} heading="alextqli@portfolio: type 'help' for more info">
				<Suspense fallback={<Loader />}>
					<TerminalContent />
				</Suspense>
			</Default>
		</Layout>
	);
};

export default Window;
