import React from "react";
import styled from "styled-components";
import bg from "../../static/bgbig.jpg";
import DockContent from "../Content/DockContent";
import MenuContent from "../Content/MenuContent";

const Wrapper = styled.div`
	display: grid;
	place-items: center;
	min-height: 100vh;
	max-height: 100vh;
	background: url(${bg}) no-repeat center center;
	background-size: cover;
	overflow: hidden;
`;

const Layout = props => {
	return (
		<>
			<MenuContent programName={props.programName} />
			<Wrapper>
				{props.children}
			</Wrapper>
			<DockContent />
		</>
	);
};

export default Layout;
