import React from "react";
import {createBrowserRouter, createRoutesFromElements, Route, Outlet, RouterProvider, Navigate} from 'react-router-dom';
import Window from "../Components/Pages/Window.jsx";
import VSCode from "../Components/Pages/VSCode.jsx";
import Contact from "../Components/Pages/Contact.jsx";
import Projects from "../Components/Pages/Projects.jsx";
import Resume from "../Components/Pages/Resume.jsx";



const Routing = () => {
    
    const router = createBrowserRouter(
        createRoutesFromElements(
        <Route path = "/" element = {<Root />}>
          <Route index element = {<Window />} />
            <Route path = "/vscode" element = {<VSCode />} />
            <Route path = "/projects" element = {<Projects />} />
            <Route path = "/resume" element = {<Resume />} />
            <Route path = "/contact" element = {<Contact />} />
            <Route path="*" element={<Navigate to="/" />} />

        </Route>

     
        )
    )
	return (
        <RouterProvider router = {router} />
	);
};
export default Routing;
const Root = () => {
    return(
    <>  
      <div><Outlet /></div>
    </>
      )
  }

