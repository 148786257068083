import React, { Suspense, lazy } from "react";
import Layout from "./Layout";
import Loader from "../Loader";
import Default from "./Default";
const VSCodeContent = lazy(() => import("../Content/VSCodeContent"));

const VSCode = () => {
	return (
		<Layout programName="Code">
			<Default height="90%" heading="Visual Studio Code" resizable={false}>
				<Suspense fallback={<Loader />}>
					<VSCodeContent />
				</Suspense>
			</Default>
		</Layout>
	);
};

export default VSCode;
