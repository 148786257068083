import React, { useState, useEffect, Suspense, lazy } from "react";
import Layout from "./Layout";
import Loader from "../Loader";
import Default from "./Default";
import Popup from "./Popup";

const ProjectContent = lazy(() => import("../Content/ProjectContent"));

const VSCode = () => {
	const [popups, setPopups] = useState([]);
	const [keys, setKeys] = useState([]);
	const [popup, displayPopup] = useState(null);


	useEffect(() => {
		console.log('Added Popup', popup);
		if (popup != null){
			console.log(popup.key);
			if(!keys.includes(popup.key)){
				setPopups((prev) => [...prev, popup]);
				setKeys((prev) => [...prev, popup.key]);
			}
		}
	  }, [popup]);	

	useEffect(() => {
		console.log('Updated PopupArray:', popups);
	  }, [popups]);

	const updateKey = (index) => {
		const newArray = keys.filter(item => item !== index);
		setKeys(newArray);
		for (let i = popups.length - 1; i >= 0; i--) {
			if (popups[i].key === index) {
			  popups.splice(i, 1);
			}
		  }
		  

	  };
	
	return (
		<Layout programName="Projects">
			<Default height="80%" heading="Projects" resizable={false} >
				<Suspense fallback={<Loader />}>
					<ProjectContent displayPopup = {displayPopup}/>
				</Suspense>
			</Default>

			{popups.map(({component: Component, key}) => (
				<Popup heading="Projects" resizable={false} componentKey={key} keys={keys} updateKey={updateKey}>
					<Suspense fallback={<Loader />}>

						<Component />
					</Suspense>
				</Popup>
				 
			))}

		</Layout>
	);
};

export default VSCode;
