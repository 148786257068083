import React, { Suspense, lazy } from "react";
import Layout from "./Layout";
import Loader from "../Loader";
import Default from "./Default";
const ResumeContent = lazy(() => import("../Content/ResumeContent"));

const Resume = () => {
	return (
		<Layout programName="Preview">
			<Default height="90%" heading="Resume" resizable={false} >
				<Suspense fallback={<Loader />}>
					<ResumeContent />
				</Suspense>
			</Default>
		</Layout>
	);
};

export default Resume;
